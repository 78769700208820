<template>
	<div>
		<el-container>
		  <el-header>
				<MenuThird :listName="'applet'"></MenuThird>
			</el-header>
		  <el-main>
				<router-view/>
			</el-main>
		</el-container>
	</div>
</template>

<script>
	import MenuThird from '../../../components/menu/menu-third.vue';
	export default {
		name: 'applet',
		components: { MenuThird }
	}
</script>

<style lang="less" scoped>
.el-header{
	padding: 0;
	height: 40px !important;
}
.el-main{
	height: calc(100vh - 160px);
	padding: 0;
	margin-top: 20px;
}
.el-main::-webkit-scrollbar-track {
	background-color: #ffffff;
}
.el-main::-webkit-scrollbar {
	width: 0;
}
.el-main::-webkit-scrollbar-thumb {
	background-color: #999999;
}
</style>
